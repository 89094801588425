export class AiAccessHelper {
    private static authorizedUsernames = [
        'ngadmin', // SuperAdmin 19999
        'apons@pbc-concept.com',
        'jessica@avantagesrh.re',
        'dtvcoaching@gmail.com',
        'dorinef@hotmail.fr',
        'christophe.couvidou@gmail.com',
        'Muriel.husson@intalents.fr',
        'patricia.bourdon.fpo1@gmail.com',
        'stephane@avantagesrh.com',
        'clement@clement-bergon.com',
        'marie.charcosset@ngenio-france.com',
        'CREACORDH',
        'NicolePare',
        'nancycnormand@lesalchimistesconseils.com',
        'rchausse@lesalchimistesconseils.com',
        'mbelanger@lesalchimistesconseils.com',
        'nicolas.bonnin@ngenio-france.com',
        'angers@dlg49.fr',
        'MarcotteJ'
    ];

    public static isUserAuthorized(username: string): boolean {
        return AiAccessHelper.authorizedUsernames.includes(username);
    }
}
